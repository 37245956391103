@import "color-palette";

.FilterButton {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    max-width: 346px;
    width: 100%;
    height: 56px;
    border-radius: 10px;
    background: none;
    border: none;
    cursor: pointer;

    >svg {
        margin-right: 10px;
    }

    &:hover {
        text-decoration: none;
    }

    &_disabled {
        opacity: 0.5;
    }

    &_loading {
        opacity: 0.5;
    }
}

.Primary {
    background: $color-bg-blue;
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 22px;
    text-align: center;
    color: $color-text-white;
}

.Cancel {
    border: 1px solid $color-border-pink;
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 18px;
    color: $color-text-black-green;
    max-width: 170px;
}

.PrimaryLight {
    border: 1px solid $color-border-blue;
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    line-height: 18px;
    color: $color-text-aqua;
}


.loader {
    margin-right: 15px;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    position: relative;
    animation: rotate 1s linear infinite
}

.loader::before {
    content: "";
    box-sizing: border-box;
    position: absolute;
    inset: 0px;
    border-radius: 50%;
    border: 5px solid $color-active-white;
    animation: prixClipFix 2s linear infinite;
}

@keyframes rotate {
    100% {
        transform: rotate(360deg)
    }
}

@keyframes prixClipFix {
    0% {
        clip-path: polygon(50% 50%, 0 0, 0 0, 0 0, 0 0, 0 0)
    }

    25% {
        clip-path: polygon(50% 50%, 0 0, 100% 0, 100% 0, 100% 0, 100% 0)
    }

    50% {
        clip-path: polygon(50% 50%, 0 0, 100% 0, 100% 100%, 100% 100%, 100% 100%)
    }

    75% {
        clip-path: polygon(50% 50%, 0 0, 100% 0, 100% 100%, 0 100%, 0 100%)
    }

    100% {
        clip-path: polygon(50% 50%, 0 0, 100% 0, 100% 100%, 0 100%, 0 0)
    }
}