$mainPageLocationFilterZIndex: 99;
$mainPageLocationConfirmZIndex: 90;
$scrollButton: 100;
$galleryButtonsZIndex: 40;
$mobileMenuZIndex: 999;
$cardOnMapZIndex: 39;
$deleteCompareCard: 49;
$catalogFilterZIndex: 50;
$layoutUserMenuZIndex: 51;
$catalogMobileCardZIndex: 49;
$placeToCheckFormComplete: 9999;
$favoriteIconZIndex: 49;
$compareIconZIndex: 49;
$stickyCompareCards: 95;
$disabledText: 99;

$mobileFullScreenWindow: 9999;


$catalogCardHovered: 998;

$loaderContMap: 999;

$horizontalCardButtonsMenu: 9999;

