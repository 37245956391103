.FilterDoubleInput {}


.Row {
    display: flex;
    // justify-content: space-between;
    gap: 10px;
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: 50%;
}

.Col {
    flex-grow: 1;
    width: 100%;

    label {
        font-style: normal;
        font-weight: 500;
        font-size: 13px;
        line-height: 16px;
        /* identical to box height */


        color: #26365C;
        margin-bottom: 5px;
        display: block;
    }
}