@import "mixins";

.Vertical {
    display: inline-grid;
    width: 100%;
    justify-content: center;
    align-items: center;
    grid-template-columns: repeat(4, 1fr);

    @media screen and (max-width: 930px) {
        grid-template-columns: repeat(3, 1fr);
    }

    @media screen and (max-width: 600px) {
        grid-template-columns: repeat(2, 1fr);
    }

    @media screen and (max-width: 460px) {
        grid-template-columns: repeat(1, 1fr);
    }

    gap: 16px;

    > li {
        width: 100%;
        min-width: 0;
        //
    }
}

.Horizontal {
    display: flex;
    flex-direction: column;
}

.CategoryChart {
    margin-bottom: 30px;
}

.CatalogList {
    overflow-anchor: none;
    margin-top: 30px;
    margin-bottom: 30px;

    align-items: center;

    h1 {
        font-style: normal;
        font-weight: 600;
        font-size: 24px;
        line-height: 29px;
        color: #1d1d1b;
        padding: 0;
        margin: 0;
        position: relative;
    }
}

.Header {
    width: 100%;
    // overflow: hidden;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 40px;
}

.HeaderMenu {
    display: flex;
    align-items: center;
}

.CardList {
    @include list;
    margin-bottom: 40px;
}

.Divider {
    width: 0.5px;
    height: 30px;
    border-left: 0.5px solid #b5bccb;
    margin: 0 20px;
}

.HorizontalBtn {
    margin-right: 10px;
}

.HorizontalBtn,
.VerticalBtn,
.Divider {
    @media screen and (max-width: 1000px) {
        display: none;
    }
}

.Select {
    margin-right: 16px;
    @media (max-width: 785px) {
        display: none;
    }
}

.Select2 {
    @media (max-width: 520px) {
        display: none;
    }
}

.ForcedToShow {
    &.Header {
        @media (max-width: 665px) {
            flex-direction: column;
            align-items: baseline;
            .Select,
            .Select2 {
                display: block;
                margin-top: 1em;
            }
        }
    }
}

.PrimaryButton {
    margin: 0 auto;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    padding: 10px;
    width: 220px;
    height: 56px;
    border: 1px solid #3b85f7;
    border-radius: 10px;
    background-color: white;
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    line-height: 18px;

    color: #3b85f7;
}

.PrimaryButton {
    margin: 0px auto 0 auto;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    padding: 10px;
    width: 220px;
    height: 56px;
    border: 1px solid #3b85f7;
    border-radius: 10px;
    background-color: transparent;
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    line-height: 18px;

    color: #3b85f7;
}

.Error {
    width: 200%;

    @media (max-width: 1000px) {
        width: 100%;
    }
}

.HeaderCont {
    // display: flex;
    position: relative;
}

.Total {
    font-size: 12px;
    font-weight: normal;
    position: relative;
    top: -9px;
    // right: -53px;
    width: 50px;
    display: inline;

    @media screen and (max-width: 500px) {
        font-size: 12px;
        font-weight: normal;
        position: relative;
        top: -8px;
        right: 0;
        /* width: 50px; */
        display: inline;
    }
}
