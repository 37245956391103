.LocationButton {
    min-width: 110px;
    height: 30px;
    cursor: pointer;

    background: rgba(59, 133, 247, 0.1);
    border-radius: 8px;
    padding: 6px 11px;
    display: flex;
    align-items: center;

    >svg {
        margin-right: 10px;
        @media (max-width: 768px) {
            margin-right: 7px;
        }
    }


    label {
        cursor: pointer;
        font-style: normal;
        font-weight: 600;
        font-size: 13px;
        @media (max-width: 768px) {
            font-size: 11px;
        }
        line-height: 16px;


        color: #1D1D1B;
    }
}