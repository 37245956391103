.FilterLineInput {
    label {
        font-style: normal;
        font-weight: 500;
        font-size: 13px;
        line-height: 16px;
        color: #26365C;
        margin-bottom: 5px;
        display: block;
    }
}