@import "color-palette";
@import "mixins";

.rangeInput {
    display: flex;
    width: 100%;
    justify-content: space-between;
    gap: 5px;
    align-items: center;
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: 50%;

    @include placeholder {
        color: $color-input-accent;
    }

    &__divider {
        width: 10px;
        min-width: 10px;
        background: $color-divider-dark;
        height: 1px;
    }

    &__from,
    &__to {
        height: 50px;
        padding: 15px;
        border: 1px solid $color-divider-border;
        border-radius: 10px;
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 20px;
        color: $color-input-accent;
        width: inherit;
    }

    &__select {
        flex-grow: 1;
        width: 100%;
    }
}