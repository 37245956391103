@import "color-palette";

.LocationFilter {
    padding: 10px 0;
    width: 420px;

    &.Multiple{
        min-height: 315px;
    }

    background: $color-bg-white;
    border: 0.5px solid $color-border-light;
    box-shadow: 0 6px 30px $shadow-light-07;
    border-radius: 16px;

    @media screen and (max-width: 520px) {
        width: calc(100vw - 100px);
    }
}

.Divider {
    height: 1px;
    border-bottom: 0.5px solid $color-divider-border;
    margin: 0 20px;
    width: calc(100% - 40px);
}

.DividerFull {
    @extend .Divider;
    margin: 0;
    width: 100%;
}

.TagsList {
    padding: 20px 20px;
    display: flex;
    flex-wrap: wrap;
    gap: 13px;
}

.Tag {
    padding: 10px;
    height: 40px;
    background: $color-bg-smoky-white;
    border-radius: 10px;

    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    color: $color-text-black-green;
    display: flex;
    align-items: center;
    justify-content: center;

    >svg {
        cursor: pointer;
        margin-left: 8px;
    }
}


.Footer {
    padding: 0 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 50px;

    a {
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 20px;
        color: $color-text-aqua;
        position: relative;
        top: 5px;
    }
}


.SearchInput {
    padding: 0 20px;
    display: flex;
    align-items: center;
    height: 50px;

    >svg {
        margin-right: 10px;
    }

    input {
        flex-grow: 1;
        border: none;
        outline: none;
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 20px;
        display: flex;
        align-items: center;
        color: $color-input-accent;

        &::placeholder {
            font-style: normal;
            font-weight: 500;
            font-size: 16px;
            line-height: 20px;
            display: flex;
            align-items: center;
            color: $color-input-accent;
        }
    }
}

.DetectButton {
    background-color: transparent;
    color: $color-text-aqua;
    font-size: 16px;
    font-weight: 600;
    text-decoration: none;
    border: none;
    margin: 12px 0 12px 20px;
    padding: 0;
    cursor: pointer;
}


@media screen and (max-width: 520px) {
    .SearchInput {
        input, input::placeholder{
            font-size: 13px;
            line-height: 17px;
            overflow: hidden;
            text-overflow: ellipsis;
        }
    }
}

.Slider {
    padding: 20px;
    margin-bottom: 20px;

    label {
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 20px;
        display: block;
        color: $color-text-blue;
        margin-bottom: 20px;
    }

}


.CityList {
    list-style: none;
    padding-left: 0;
    min-height: 200px;
    margin-bottom: 0;

    li {
        padding-left: 50px;
        display: flex;
        align-items: center;
        &:hover{
            background: $color-bg-ghost-white;
        }
        cursor: pointer;
        height: 40px;

        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 20px;
        color: $color-text-blue;
    }
}


ul{
    li.UlDivider{
        color: gray;
        font-size: 12px;
        padding-left: 21px;
        height: 25px;
        &:hover{
            background: transparent;
        }
    }
}