@import "color-palette";
@import "zIndexes";

.CatalogCard {
    padding: 22px 20px 0 20px;
    border-radius: 16px;

    &:hover {
        z-index: $catalogCardHovered;
        background: $color-bg-white;
        box-shadow: 0 10px 30px $shadow-light-08;
        border-radius: 16px;

        .Divider {
            border-color: transparent;
        }
    }
}

.CatalogCardContainer {
    position: relative;
    display: flex;
    >div {
        flex-grow: 1;
    }
}

.Divider {
    padding-top: 20px;
    border-bottom: 0.5px solid $color-divider-border;
    width: 100%;
    box-sizing: border-box;
    height: 1px;
}

.Name {
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
    color: $color-primary-black;
}

.Info {
    max-width: 257px;
    padding-top: 10px;
    margin-right: 20px;

    .TitleContainer {
        >a {
            @extend .Name;
            margin-bottom: 5px;
        }
    }

}

.Tags {
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    color: $color-primary-black;

    display: flex;
    gap: 20px;
    margin-bottom: 18px;
}

.Props {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    color: $color-text-accent;
    margin-bottom: 18px;
    ;
}


.MoreProps {
    display: flex;
    justify-content: space-between;
    margin-bottom: 25px;
}

.MorePropLabels,
.MorePropVals {
    display: flex;
    flex-direction: column;

}

.MorePropLabels>label,
.MorePropVals>div {
    display: flex;
    justify-content: space-between;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    color: $color-text-accent;
    margin-bottom: 5px;
}

.Location {
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 16px;
    color: $color-text-accent;
    display: flex;
    align-items: center;

    >svg {
        margin-right: 8px;
    }
}

.Source {
    padding-top: 10px;
    align-items: center;
    display: flex;
    flex-direction: column;
    font-weight: 500;
    font-size: 11px;
    line-height: 13px;
    color: $color-text-accent;

    &_icon {
        width: 73px;
        min-height: 20px;
        margin-bottom: 8px;
    }
}


.Price {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    padding-top: 10px;

    &__source {
        font-style: normal;
        font-weight: 600;
        font-size: 20px;
        line-height: 24px;
        color: $color-primary-black;
    }

    &__usd {
        font-size: 14px;
        line-height: 16px;
        font-weight: 600;
        color: $color-text-accent;
    }
}

.Buttons {
    padding-top: 10px;
    max-width: 70px;
    display: flex;
    gap: 10px;
    justify-content: flex-end;
}

.FavoriteOffer {
    width: 30px;
    height: 30px;
    border: none;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.CompareOffer {
    width: 30px;
    height: 30px;
    border: none;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;

    &__icon {
        width: 20px;
        height: 20px;
        fill: $color-bg-accent;

        &--active {
            fill: $color-bg-blue;
        }

        &:hover {
            fill: $color-bg-blue;
        }
    }


}

.HideDivider {
    border-color: transparent;
}

.TitleContainer {
    display: flex;
    align-items: center;
}

.TitleContainer > a {
    display: -webkit-box;
    align-items: center;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-box;
    display: box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
}

.ButtonCont{
    position: absolute;
    right: 0;
    bottom: 0;
}

.buttonsBlockContent{
    width: 100%;
}

.Root{
    position: relative;
}
.DisabledText {
    z-index: $disabledText;
    position: absolute;
    width: 138px;
    height: 32px;
    top: 25px;
    left: 25px;
    display: flex;
    align-items: center;
    justify-content: center;

    background: $color-bg-hidden;
    border-radius: 6px;
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 16px;
    color: $color-text-white;
}
.Disabled {
    filter: opacity(0.3);
    cursor: unset !important;
    * {
        cursor: unset !important;
    }
    *:hover {
        cursor: unset !important;
        text-decoration: none;
    }
}