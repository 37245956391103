@import "color-palette";
@import "zIndexes";

.MainFilter {
    // width: 1140px;
    min-height: 260px;
    background: $color-bg-white;
    border-radius: 16px;
    box-sizing: border-box;

    padding: 20px 30px 30px 30px;
}

.MainPage{
        box-shadow: 0px 8px 30px $shadow-light-08;
}



.Header {
    border-bottom: 0.5px solid $color-divider-border;
    padding-bottom: 20px;
    display: flex;
    justify-content: space-between;
    position: relative;
}

.Footer {
    display: flex;
    gap: 20px;
    padding-top: 30px;

    display: flex;
    justify-content: center;
    > a:nth-child(1) {
        // display: none;
    }
    @media screen and (max-width: 1178px) {
        .CancelContainer {
            display: none;
        }
        

        > a {
            max-width: calc(25% - 20px);
        }
    }

    @media screen and (max-width: 850px) {
        gap: 40px;
        > a:nth-child(1) {
            // display: none;
        }

      
      

        > a {
            max-width: calc(50%);
        }
    }
    @media screen and (max-width: 850px) {
        gap: 20px;
    }

    @media screen and (max-width: 470px) {
        flex-direction: column;
        > a:nth-child(1) {
            // display: none;
        }


        > a {
            max-width: 100%;
        }
    }
}

.ExpandedMode {
    .Footer {
        flex-direction: row-reverse;
        padding-top: 0px;
        @media screen and (max-width: 470px) {
            flex-direction: column;

            > a {
                max-width: 100%;
            }
        }
    }
}

.MainLine {
    padding: 30px 0 0 0;
    flex-wrap: wrap;

    @media screen and (max-width: 1178px) {
        .Col:nth-child(1),
        .Col:nth-child(2),
        .Col:nth-child(3) {
            flex-grow: 1;
            width: 100%;
            max-width: calc(50% - 20px);
        }
    }

    @media screen and (max-width: 800px) {
        .Col:nth-child(1),
        .Col:nth-child(2),
        .Col:nth-child(3) {
            flex-grow: 1;
            width: 100%;
            max-width: 100%;
        }
    }
}

.MainPage{

}

.InnerPage{

}

.CancelContainer {
    display: block;
    width: 100%;
    display: flex;
    justify-content: flex-start;
}

.Row {
    display: flex;
    gap: 20px;
    justify-content: space-between;

    .Col {
        max-width: 346px;
        width: 100%;
        flex-grow: 1;
    }
}

.ExpandedFilters {
    display: grid;
    grid-template-columns: repeat(3, 1fr);

    @media screen and (max-width: 900px) {
        grid-template-columns: repeat(2, 1fr);
    }

    @media screen and (max-width: 550px) {
        grid-template-columns: repeat(1, 1fr);
    }

    gap: 20px 13px;
    margin-bottom: 20px;
}

.ExpandedFilter {
    // border: 1px solid blue;
    min-height: 50px;
}

.Divider {
    border-bottom: 0.5px solid $color-divider-border;
    margin: 30px 0;
}

.ModeSwitcher {
    user-select: none;
    -moz-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
    cursor: pointer;
    display: flex;
    align-items: center;
    font-style: normal;
    font-weight: 600;
    font-size: 13px;
    line-height: 16px;

    text-align: right;

    color: $color-switcher;

    > svg {
        margin-left: 6px;
        transform: rotate(180deg);
    }

    &.Expanded {
        > svg {
            transform: rotate(0deg);
        }
    }

    @media screen and (max-width: 400px) {
        font-size: 12px;
        line-height: 14px;
    }
}

.LocationConfirmCont {
    z-index: $mainPageLocationConfirmZIndex;
    position: absolute;
    top: 35px;
}

.LocationFilterCont {
    z-index: $mainPageLocationFilterZIndex;
    position: absolute;
    top: 35px;
}

.FilterButton {
}

.customSelect {
    font-size: inherit;
    text-align: center;
    padding: 10px;
    color: $color-input-accent;
}

.groupSelect {
    margin-bottom: 10px;
    border-bottom: 1px solid #e0e0e0;
}