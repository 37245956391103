@import 'color-palette';

.LocationConfirm {
  padding: 16px 24px;
  width: 208px;
  background: $color-bg-white;
  border: 0.5px solid $color-border-light;
  box-shadow: 0 5px 17px $shadow-light-07;
  border-radius: 8px;

  display: flex;
  flex-direction: column;
  gap: 10px;

  &__question {
    font-size: 14px;
    font-weight: 500;
  }

  &__button {
    font-size: 14px;
    border: none;
    border-radius: 6px;
    padding: 6px 12px;
    font-weight: 500;
    cursor: pointer;

    &-confirm {
      background-color: $color-bg-blue;
      color: $color-text-white;
    }

    &-change {
      border: 1px solid $color-bg-blue;
      background-color: $color-bg-white;
      color: $color-text-aqua;
    }
  }
}